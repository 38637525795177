import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectText from '../../components/Project/ProjectText';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import { Helmet } from 'react-helmet-async';

class Passengers extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.passengers;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Passengers is a soundscape from my final year degree show';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="Passengers is a soundscape from my final year degree show"
					/>

					<ProjectText
						text={`<p>Entering a darkened room, the listen finds themselves walking through a corridor and after a few paces comes upon a resting area. Now lying down with a soft bedding below them, the listener puts on a set of headphones and presses a red button to begin the piece of music. After some minutes the user begins to drift in and out of their thoughts as the composition plays on.</p>`}
					/>

					<ProjectTrack
						title="Passengers"
						id="74886973"
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.venice}
						next={projectsData.projects.yaitte}
					/>
				</article>
			</>
		)
	}
}

export default Passengers;
