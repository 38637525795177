import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import projectsData from '../../json/projects';
import PageHeader from '../../components/PageHeader/PageHeader';
import ProjectText from '../../components/Project/ProjectText';
import ProjectImages from '../../components/Project/ProjectImages';
import BackHome from '../../components/BackHome/BackHome';
import ProjectFooter from '../../components/Project/ProjectFooter';

class Mashu extends Component {

    constructor(props) {
        super(props);

        this.project = projectsData.projects.mashu;

        this.pageTitle = `${this.project.title} – Jase Warner`;
        this.pageUrl = `https://jase.io/${this.project.slug}`;
        this.pageDescription = 'Shopify consultancy and theme development for brand specialising in designer vegan handbags';
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta property="og:title" content={this.pageTitle} />
                    <meta property="og:description" content={this.pageDescription} />
                    <meta property="og:url" content={this.pageUrl} />
                </Helmet>
                <article>
                    <PageHeader
                        title={this.project.title}
                        tags={this.project.tags}
                        description={this.pageDescription}
                    />

                    <ProjectText
                        text={`<p><a href="https://mashu.co.uk/" target="_blank" rel="noopener noreferrer">Mashu</a> is a brand specialising in sustainable handbags, marrying "contemporary design with traditional artisan craftsmanship, creating beautifully constructed accessories that feature vegan, sustainable and recyclable materials".</p><p>I worked with Mashu mainly as a consultant, but also did some development work on their Shopify theme to get it to where it needed to be.</p>`}
                    />

                    <ProjectImages
                        firstSrc={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02.jpg`}
                        firstSrc2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02@2x.jpg`}
                        secondSrc={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-03.jpg`}
                        secondSrc2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-03@2x.jpg`}
                    />

                    <BackHome/>

                    <ProjectFooter
                        prev={projectsData.projects.winterSwimmer}
                        next={projectsData.projects.grato}
                    />
                </article>
            </>
        )
    }
}

export default Mashu;
